import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateNotificationWrapper } from "./style";
import {
  DEBOUNCE_TIME,
  deviceTypes,
  screenNames,
} from "../../../config/constant";
import { getActiveLanguages } from "../../../core/utils";
import alertIcon from "../../../assets/Images/icons/alertIcon.svg";
import ChipList from "../../../assets/SharedComponents/SearchableDropdown/ChipList";
import SearchableAutoCompleteWithChips from "../../../assets/SharedComponents/SearchableDropdown/SearchableAutoCompleteWithChips";
import { toast } from "react-toastify";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";

const AddNewNotification = () => {
  const navigate = useNavigate();

  const [loading, _loading] = useState(false);

  const [notification, _notification] = useState({
    title: "",
    body: "",
    screen_name: "",
    users_ids: [],
    device_type: 4,
    language_id: "",
  });

  const [users, _users] = useState([]);

  const [chips, setChips] = useState([]);

  const [username, _username] = useState("");

  useEffect(() => {
    let timer = null;
    if (username?.length > 1)
      timer = setTimeout(() => {
        getUsers();
      }, DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [username]);

  const handleSubmit = () => {
    if (loading) {
      return;
    }
    if (notification.title === "") {
      toast.error("Notification title is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (notification.body === "") {
      toast.error("Notification body is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (notification.screen_name === "") {
      toast.error("Screen name is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (notification.device_type === "") {
      toast.error("Device type is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (notification.language_id == "") {
      delete notification["language_id"];
    }
    let payload = notification;
    payload.device_type = parseInt(payload.device_type);
    if (payload.users_ids && payload.users_ids.length == 0) {
      delete payload["users_ids"];
    }
    if (payload.language_id) {
      payload.language_id = parseInt(payload.language_id);
    }
    _loading(true);
    API.post(APP_URLS.CUSTOM_NOTIFICATIONS, payload)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success("Custom notification has been trigger successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/custom-notifications");
          _loading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          if (resp.data.data.message) {
            error_message = resp.data.data.message;
          } else {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getUsers = () => {
    API.get(
      `${APP_URLS.LIST_USERS}/list/all?keyword=${username}&with_email=true`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _users(resp.data.items);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map(
            (error, index) => (error_message = resp.data.data[error][0])
          );
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleChange = (key, value) => {
    if (key === "title" || key === "body") {
      if (value.length > 255) {
        toast.error("Maximum you can add upto 255 characters");
        return;
      }
    }
    _notification((prevState) => ({ ...prevState, [key]: value }));
  };

  //to set array of Ids of selected options
  const getSelectedData = () => {
    const selectedOptionIds = chips.map((option) => parseInt(option.id));
    _notification((prevState) => ({
      ...prevState,
      users_ids: selectedOptionIds,
    }));
  };

  //set chips from dropdown on selecting values from dropdown
  const handleChipChange = (value) => {
    setChips(value);
  };

  //removing chip from selected chips
  const handleDelete = (stringToDelete) => {
    const updatedChips = chips?.filter(
      (value) => value.id !== stringToDelete.id
    );
    setChips(updatedChips);
  };

  return (
    <CreateNotificationWrapper>
      <div className="mt-2">
        <div
          className="backContainer ms-4"
          onClick={() => navigate("/custom-notifications")}
        >
          <span className="backButton">x</span>
          <span className="px-3 backButton">|</span>
          <span className="backButton">Cancel</span>
        </div>
      </div>
      <div className="headerContainer">
        <h1>Trigger New notification</h1>
      </div>
      <div className="row">
        <div className="d-flex justify-content-end save-btn mt-3">
          <button
            className={(loading ? "disabled" : "") + " add-button"}
            onClick={handleSubmit}
            disabled={loading}
          >
            Save
          </button>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12 mt-3">
                <label htmlFor="name" className="form-label fieldLabel">
                  Title
                  <img
                    src={alertIcon}
                    className="tooltipIcon"
                    title="Max 255 Characters"
                    alt="Max 255 Characters"
                  />
                </label>
                <textarea
                  className="form-control langaugeFields"
                  id="name"
                  rows="3"
                  value={notification.title}
                  placeholder="Notification Title"
                  onChange={(e) => handleChange("title", e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12 mt-3">
                <label htmlFor="body" className="form-label fieldLabel">
                  Body
                  <img
                    src={alertIcon}
                    className="tooltipIcon"
                    title="Max 255 Characters"
                    alt="Max 255 Characters"
                  />
                </label>
                <textarea
                  className="form-control langaugeFields"
                  id="language"
                  rows="3"
                  placeholder="Notification Body"
                  value={notification.body}
                  onChange={(e) => {
                    handleChange("body", e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <label
              htmlFor="productType"
              className="form-label product-type fieldLabel"
            >
              Screen Name
            </label>
            <select
              name="productType"
              id="productType"
              className="form-control"
              onChange={(e) => handleChange("screen_name", e.target.value)}
            >
              <option hidden>Select Screen name</option>
              {screenNames.map((screenName) => {
                return (
                  <option value={screenName.value} key={screenName.value}>
                    {screenName.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mt-3">
            <label
              htmlFor="productType"
              className="form-label product-type fieldLabel"
            >
              Select Language (optional)
              <img
                src={alertIcon}
                className="tooltipIcon"
                title="Notifications will be sent only to users who have selected the language below. If no language is selected, the notification will be sent to all users."
              />
            </label>
            <select
              name="language"
              id="language"
              className="form-control"
              onChange={(e) => handleChange("language_id", e.target.value)}
            >
              <option hidden>Select Language</option>
              {getActiveLanguages().map((language) => {
                return (
                  <option
                    value={language.id}
                    key={`${language.name}-${language.id}`}
                  >
                    {language.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-4 mt-3">
            <label
              htmlFor="deviceType"
              className="form-label product-type fieldLabel"
            >
              Device Type
              <img
                src={alertIcon}
                className="tooltipIcon"
                title="Notifications will be sent only to users who have selected device. If no device is selected, the notification will be sent to all users."
              />
            </label>
            <select
              name="deviceType"
              id="deviceType"
              className="form-control"
              value={notification.device_type}
              onChange={(e) => handleChange("device_type", e.target.value)}
            >
              <option hidden>Select Device</option>
              {deviceTypes.map((device) => {
                return (
                  <option value={device.value} key={device.value}>
                    {device.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-4 mt-3">
            <label className="form-label product-type fieldLabel">
              Select Users (optional)
              <img
                src={alertIcon}
                className="tooltipIcon"
                title="Notifications will be sent only to selected users. If no users is selected, the notification will be sent to all."
              />
            </label>
            <SearchableAutoCompleteWithChips
              options={users}
              onChange={handleChipChange}
              chips={chips}
              func={_username}
              keyword={username}
              getSelectedData={getSelectedData}
              placeholder="Select Users"
            />
            <div className="mt-1">
              <ChipList chips={chips} onDelete={handleDelete} />
            </div>
          </div>
        </div>
      </div>
    </CreateNotificationWrapper>
  );
};

export default AddNewNotification;
