import React, { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Auction from "../pages/Auction/Auction";
import AuctionEvent from "../pages/Auction/AuctionEvent";
import CreateAuction from "../pages/Auction/CreateAuction";
import AuctionAds from "../pages/Auction/AuctionAds";
import Plan from "../pages/Plan/CreatePlan";
import CreateCoupon from "../pages/Coupon/CreateCoupon";
import PlanTable from "../pages/Plan/Plan";
import PlanDescription from "../pages/Plan/PlanDescription";
import Features from "../pages/Features/Features";
import Coupon from "../pages/Coupon/Coupon";
import Commission from "../pages/Commission/Commision";
import CreateCommission from "../pages/Commission/CreateCommission";
import Company from "../pages/Company/Company";
import CompanyDetails from "../pages/Company/CompanyDetails";
import ProspectDealers from "../pages/Dealers/ProspectDealers";
import ImportCsv from "../pages/Dealers/ImportProspectData";
import DataValidate from "../pages/Dealers/DataValidate";
import Slides2 from "../pages/Slide2/Slides";
import Slider from "../pages/Slide2/Slider/index";
import Users from "../pages/User/Users";
import DetailMember from "../pages/User/DetailMember";
import CreateCensured from "../pages/User/CreateCensured";
import CreateVehicleBody from "../pages/Translate/Body/CreateVehicleBody";
import EditVehicleBody from "../pages/Translate/Body/EditVehicleBody";
import CreateVehicleFuel from "../pages/Translate/Fuel/CreateVehicleFuel";
import EditVehicleFuel from "../pages/Translate/Fuel/EditVehicleFuel";
import EditVehicleGear from "../pages/Translate/GearBox/EditVehicleGearBox";
import CreateVehicleGear from "../pages/Translate/GearBox/CreateVehicleGearBox";
import CreateVehicleColor from "../pages/Translate/Color/CreateVehicleColor";
import EditVehicleColor from "../pages/Translate/Color/EditVehicleColor";
import CreateVehicleEquipment from "../pages/Translate/Equipment/CreateVehicleEquipment";
import EditVehicleEquipment from "../pages/Translate/Equipment/EditVehicleEquipment";
import CreateVehicleBatteryPosition from "../pages/Translate/BatteryPositions/CreateVehicleBatteryPosition";
import CreateVehicleBatteryTypes from "../pages/Translate/BatteryTypes/CreateVehicleBatteryTypes";
import CreateVehicleBrakes from "../pages/Translate/Brakes/CreateVehicleBrakes";
import CreateVehicleInteriors from "../pages/Translate/Interiors/CreateVehicleInteriors";
import EditVehicleBatteryPosition from "../pages/Translate/BatteryPositions/EditVehicleBatteryPosition";
import EditVehicleBatteryTypes from "../pages/Translate/BatteryTypes/EditVehicleBatteryTypes";
import EditVehicleBrakes from "../pages/Translate/Brakes/EditVehicleBrakes";
import EditVehicleInteriors from "../pages/Translate/Interiors/EditVehicleInteriors";
import CreateVehicleEnginePositions from "../pages/Translate/EnginePositions/CreateVehicleEnginePositions";
import CreateVehicleDrivingWheel from "../pages/Translate/DrivingWheel/CreateVehicleDrivingWheel";
import CreateVehicleFrameSizes from "../pages/Translate/FrameSizes/CreateVehicleFrameSizes";
import CreateVehicleSuspensions from "../pages/Translate/Suspensions/CreateVehicleSuspensions";
import CreateTargetAudiences from "../pages/Translate/TargetAudiences/CreateTargetAudiences";
import CreateVehicleConditions from "../pages/Translate/VehicleConditions/CreateVehicleConditions";
import EditVehicleDrivingWheel from "../pages/Translate/DrivingWheel/EditVehicleDrivingWheel";
import EditVehicleEnginePosition from "../pages/Translate/EnginePositions/EditVehicleEnginePosition";
import EditVehicleFrameSizes from "../pages/Translate/FrameSizes/EditVehicleFrameSizes";
import EditVehicleSuspensions from "../pages/Translate/Suspensions/EditVehicleSuspensions";
import EditTargetAudiences from "../pages/Translate/TargetAudiences/EditTargetAudiences";
import EditVehicleConditions from "../pages/Translate/VehicleConditions/EditVehicleConditions";
import CreateDepartment from "../pages/Translate/Department/CreateDepartment";
import EditDepartment from "../pages/Translate/Department/EditDepartment";
import BidList from "../pages/Auction/BidList";
import CreateVehicleDriveTypes from "../pages/Translate/DriveTypes/CreateVehicleDriveTypes";
import EditVehicleDriveTypes from "../pages/Translate/DriveTypes/EditVehicleDriveTypes";
import EditFeature from "../pages/Translate/Feature/EditFeature";
import EditPermission from "../pages/Translate/Permission/EditPermission";
import CreateVehicleMaterials from "../pages/Translate/Materials/CreateVehicleEquipments";
import EditVehicleMaterials from "../pages/Translate/Materials/EditVehicleEquipment";
import CreateLanguage from "../pages/Language/CreateLanguage";
import EditLanguage from "../pages/Language/EditLanguage";
import Dashboard from "../pages/Dashboard/Dashboard";
import CreateVehicleEngine from "../pages/Translate/Engines/CreateVehicleEngine";
import EditVehicleEngine from "../pages/Translate/Engines/EditVehicleEngine";
import CreateSex from "../pages/Translate/Sex/CreateSex";
import EditSex from "../pages/Translate/Sex/EditSex";
import EditVehicleEquipmentCategory from "../pages/Translate/EquipmentCategory/EditVehicleEquipment";
import CreateVehicleEquipmentCategory from "../pages/Translate/EquipmentCategory/CreateVehicleEquipment";
import CreateProductType from "../pages/Translate/ProductType/CreateProductType";
import EditProductType from "../pages/Translate/ProductType/EditProductType";
import Language from "../pages/Language/Language";
import DriveTypes from "../pages/Translate/DriveTypes";
import Department from "../pages/Translate/Department";
import Body from "../pages/Translate/Body";
import Fuel from "../pages/Translate/Fuel";
import GearBox from "../pages/Translate/GearBox";
import Color from "../pages/Translate/Color";
import Equipment from "../pages/Translate/Equipment";
import BatteryPosition from "../pages/Translate/BatteryPositions";
import BatteryTypes from "../pages/Translate/BatteryTypes";
import Brakes from "../pages/Translate/Brakes";
import Interiors from "../pages/Translate/Interiors";
import Permission from "../pages/Translate/Permission";
import Feature from "../pages/Translate/Feature";
import DrivingWheel from "../pages/Translate/DrivingWheel";
import EnginePosition from "../pages/Translate/EnginePositions";
import FrameSizes from "../pages/Translate/FrameSizes";
import Suspensions from "../pages/Translate/Suspensions";
import TargetAudiences from "../pages/Translate/TargetAudiences";
import VehicleConditions from "../pages/Translate/VehicleConditions";
import Materials from "../pages/Translate/Materials";
import Engines from "../pages/Translate/Engines";
import EquipmentCategory from "../pages/Translate/EquipmentCategory";
import Sex from "../pages/Translate/Sex";
import ProductType from "../pages/Translate/ProductType/ProductType";
import CreateAdminRole from "../pages/RoleManagement/CreateRole";
import InviteUser from "../pages/Inviteuser/InviteUser";
import ProductCategory from "../pages/Translate/ProductCategory";
import Invoices from "../pages/Invoices/Invoices";
import AllAuction from "../pages/Auction/Auctions";
import SuggestionText from "../pages/Translate/SuggestionText";
import CreateSuggestionText from "../pages/Translate/SuggestionText/CreateSuggestionText";
import EditSuggestionText from "../pages/Translate/SuggestionText/EditSuggestionText";
import AdminRoles from "../pages/RoleManagement/AdminRole";
import EditAdminRole from "../pages/RoleManagement/EditRole";
import BidLists from "../pages/BidList";
import BehaviorText from "../pages/Translate/UserBehaviorText";
import CreateBehaviourText from "../pages/Translate/UserBehaviorText/CreateBehaviorText";
import EditBehaviorText from "../pages/Translate/UserBehaviorText/EditBehaviorText";
import ChangePassword from "../pages/ChangePasword";
import EditAuction from "../pages/Auction/EditAuction";
import TaxRates from "../pages/TaxRates/TaxRates";
import CreateTaxRates from "../pages/TaxRates/CreateTaxRates";
import EditTaxRates from "../pages/TaxRates/EditTaxRates";
import Expertise from "../pages/Translate/Expertise";
import EditVehicleExpertise from "../pages/Translate/Expertise/EditVehicleExperise";
import VerifyId from "../pages/User/VerifyId";
import EditCoupon from "../pages/Coupon/EditCoupon";
import ClassicAdsList from "../pages/Auction/ClassicAdsList";
import Brand from "../pages/VehiclesManagement/Brands/Brand";
import CreateBrand from "../pages/VehiclesManagement/Brands/Create Brand";
import EditBrand from "../pages/VehiclesManagement/Brands/Edit Brand";
import Modals from "../pages/VehiclesManagement/Modals/Modals";
import CreateModals from "../pages/VehiclesManagement/Modals/CreateModals";
import EditModals from "../pages/VehiclesManagement/Modals/EditModals";
import TyreSize from "../pages/VehiclesManagement/TireSizes/index";
import CreateVehicleTireSizes from "../pages/VehiclesManagement/TireSizes/CreateVehicleTireSizes";
import EditVehicleTyreSizes from "../pages/VehiclesManagement/TireSizes/EditVehicleTyreSizes";
import TyreType from "../pages/VehiclesManagement/TireTypes/index";
import CreateVehicleTireTypes from "../pages/VehiclesManagement/TireTypes/CreateVehicleTireTypes";
import EditVehicleTireTypes from "../pages/VehiclesManagement/TireTypes/EditVehicleTireTypes";
import VersionCars from "../pages/VehiclesManagement/VersionCars/index";
import CreateVersionCar from "../pages/VehiclesManagement/VersionCars/CreateVersionCar";
import EditVersionCar from "../pages/VehiclesManagement/VersionCars/EditVersionCar";
import ModalItems from "../pages/VehiclesManagement/ModalsItems/ModalsItems";
import CreateModalsItems from "../pages/VehiclesManagement/ModalsItems/CreateModalsItems";
import EditModalsItems from "../pages/VehiclesManagement/ModalsItems/EditModalsItems";
import EditBackOfficeText from "../pages/Translate/BackOfficeText/EditBackOfficeText";
import CreateBackOfficeText from "../pages/Translate/BackOfficeText/CreateBackOfficeText";
import BackOfficeText from "../pages/Translate/BackOfficeText";
import Service from "../pages/Translate/Service";
import EditService from "../pages/Translate/Service/EditService";
import CreateService from "../pages/Translate/Service/CreateService";
import SubsidiaryBehaviorText from "../pages/Translate/SubsidiaryBehaviorText";
import CreateSubsidiaryBehaviourText from "../pages/Translate/SubsidiaryBehaviorText/CreateBehaviorText";
import EditSubsidiaryBehaviorText from "../pages/Translate/SubsidiaryBehaviorText/EditBehaviorText";
import EuroStandards from "../pages/VehiclesManagement/EuroStandards";
import CreateCompanyCensured from "../pages/Company/CreateCompanyCensured";
import Logger from "../pages/Logger";
import MainLayout from "../assets/Layout/MainLayout";
import ImportFile from "../pages/ImportFiles";
import Terms from "../pages/TermsandConditions/Terms";
import AddTerms from "../pages/TermsandConditions/Terms/addTerms";
import EditTerms from "../pages/TermsandConditions/Terms/editTerms";
import NomadTerms from "../pages/TermsandConditions/NomadTerms";
import AddNomadTerms from "../pages/TermsandConditions/NomadTerms/addNomadTerms";
import EditNomadTerms from "../pages/TermsandConditions/NomadTerms/editNomadTerms";
import PrivacyPolicy from "../pages/TermsandConditions/PrivacyPolicy";
import AddPrivacyPolicy from "../pages/TermsandConditions/PrivacyPolicy/addPrivacyPolicy";
import EditPrivacyPolicy from "../pages/TermsandConditions/PrivacyPolicy/editPrivacyPolicy";
import DataProtection from "../pages/TermsandConditions/DataProtection";
import AddDataProtection from "../pages/TermsandConditions/DataProtection/addDataProtection";
import EditDataProtection from "../pages/TermsandConditions/DataProtection/editDataProtection";
import LegalNotice from "../pages/TermsandConditions/LegalNotice";
import AddLegalNotice from "../pages/TermsandConditions/LegalNotice/addLegalNotice";
import EditLegalNotice from "../pages/TermsandConditions/LegalNotice/editLegalNotice";
import LegalDocuments from "../pages/TermsandConditions/LegalDocuments";
import AddLegalDocuments from "../pages/TermsandConditions/LegalDocuments/addLegalDocuments";
import EditLegalDocuments from "../pages/TermsandConditions/LegalDocuments/editLegalDocuments";
import Status from "../pages/Translate/Status";
import EditStatus from "../pages/Translate/Status/EditStatus";
import AdRemovalReasons from "../pages/Translate/AdsRemovalText";
import CreateAdRemovalReasons from "../pages/Translate/AdsRemovalText/CreateAdRemovalReasons";
import EditAdRemovalReasons from "../pages/Translate/AdsRemovalText/EditAdRemovalReasons";
import Preview from "../pages/TermsandConditions/Preview";
import EditFaqs from "../pages/FAQs/EditFAQs";
import CreateFaqs from "../pages/FAQs/CreateFAQs";
import Faqs from "../pages/FAQs";
import Countries from "../pages/Translate/Country";
import EditCountry from "../pages/Translate/Country/EditCountry";
import EditContinent from "../pages/Translate/Continent/EditContinent";
import Continents from "../pages/Translate/Continent";
import CreatePartners from "../pages/Translate/PartnerOptions/CreatePartners";
import EditPartners from "../pages/Translate/PartnerOptions/EditPartners";
import CreateContactOptions from "../pages/Translate/ContactOptions/CreateContactOptions";
import EditContactOptions from "../pages/Translate/ContactOptions/EditContactOptions";
import PartnerOptions from "../pages/Translate/PartnerOptions";
import ContactOptions from "../pages/Translate/ContactOptions";
import Partners from "../pages/Partners";
import ContactUs from "../pages/ContactUs";
import FlaggedReview from "../pages/FlaggedReview";
import DataProvider from "../pages/DataProvider";
import DataProvidersErrors from "../pages/DataProvidersErrors";
import Advertisers from "../pages/DataProvider/Advertisers";
import Volkswagen from "../pages/DataProvider/Volkswagen";
import Messanger from "../pages/Messanger/index";
import CreditPlanDetails from "../pages/Credits/CreditPlanDetails";
import Credits from "../pages/Credits";
import CreateCredits from "../pages/Credits/CreateCredits";
import EditHotdeal from "../pages/Hotdeals/EditHotdeal";
import CreateHotdeal from "../pages/Hotdeals/CreateHotdeal";
import HotdealEvents from "../pages/Hotdeals/HotdealEvents";
import AllHotdeals from "../pages/Hotdeals/Hotdeals";
import Hotdeal from "../pages/Hotdeals/Hotdeal";
import GeneralSettings from "../pages/GeneralSettings";
import HotdealAds from "../pages/Hotdeals/HotdealAds";
import CreditHistory from "../pages/CreditHistory";
import CreditHistoryReasons from "../pages/Translate/CreditHistoryReasons";
import CreateCreditHistoryReasons from "../pages/Translate/CreditHistoryReasons/CreateCreditHistoryReasons";
import EditCreditHistoryReasons from "../pages/Translate/CreditHistoryReasons/EditCreditHistoryReasons";
import CreditHistoryDetails from "../pages/CreditHistory/CreditHistoryDetails";
import CancelReservationRequest from "../pages/Hotdeals/CancelReservationRequest";
import ForumsTable from "../pages/forums";
import CreateForums from "../pages/forums/CreateForums";
import ForumFlagComments from "../pages/ForumFlagComments";
import ForumBehaviourText from "../pages/Translate/ForumBehaviourText";
import CreateForumBehaviourText from "../pages/Translate/ForumBehaviourText/CreateForumBehaviour";
import EditForumBehaviorText from "../pages/Translate/ForumBehaviourText/EditForumBehaviour";
import DepositHistory from "../pages/DepositHistory";
import DownloadZipFile from "../pages/DownloadZipFile";
import Notifications from "../pages/Notifications/Notifications";
import AddNewNotification from "../pages/Notifications/AddNotification";
// import AddCompany from "../pages/AddCompany";

const PrivateRoute = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  //Module Access
  const auctionAccess = permissions.find((o) => o.slug === "auction_event");
  const classicAdsAccess = permissions.find((o) => o.slug === "classic_ads");
  const bidsAccess = permissions.find((o) => o.slug === "bid_list");
  const featuresAccess = permissions.find(
    (o) => o.slug === "features_management"
  );
  const usersAccess = permissions.find((o) => o.slug === "users");
  const verifyUser = permissions.find((o) => o.slug === "verify_user");
  const validateUser = permissions.find((o) => o.slug === "verify_nomad_user");
  const roleAccess = permissions.find((o) => o.slug === "role_management");
  const tanslationAccess = permissions.find((o) => o.slug === "translation");
  const vehicleManagementAccess = permissions.find(
    (o) => o.slug === "vehicle_management"
  );
  const plansAccess = permissions.find((o) => o.slug === "plans");
  const couponAccess = permissions.find((o) => o.slug === "coupons");
  const taxRateAccess = permissions.find((o) => o.slug === "tax_rates");
  const commissionAccess = permissions.find(
    (o) => o.slug === "commission_management"
  );
  const slideAccess = permissions.find((o) => o.slug === "slide_management");
  const companyAccess = permissions.find((o) => o.slug === "manage_subsidiary");
  const invoiceAccess = permissions.find((o) => o.slug === "invoices");
  const logsAccess = permissions.find((o) => o.slug === "logs");
  const legalDocumentsAccess = permissions.find(
    (o) => o.slug === "legal_documents"
  );
  const faqAccess = permissions.find((o) => o.slug === "faq");
  const partnersAccess = permissions.find((o) => o.slug === "partner");
  const contactAccess = permissions.find((o) => o.slug === "contact_us");
  const importedDealer = permissions.find((o) => o.slug === "imported_dealer");
  const chats = permissions.find((o) => o.slug === "chats");
  const hotdealAccess = permissions.find((o) => o.slug === "hotdeal_event");
  const creditAccess = permissions.find((o) => o.slug === "credits");
  const hotdealvehicleAccess = permissions.find(
    (o) => o.slug === "hotdeal_vehicle_action"
  );

  return (
    <>
      <MainLayout>
        <Routes>
          {/* Dashboard */}
          <Route exact path="/dashboard" element={<Dashboard />} />

          {/* Auction */}
          {auctionAccess ? (
            <Fragment>
              {/* Read permission Granted */}
              {auctionAccess?.permissions.find((o) => o.slug === "read") ? (
                <Fragment>
                  <Route
                    exact
                    path="/auction-events"
                    element={<AuctionEvent />}
                  />
                  <Route exact path="/auctions" element={<AllAuction />} />
                  <Route
                    exact
                    path="/auction/:auctionId"
                    element={<Auction />}
                  />
                  <Route
                    exact
                    path="/auction-ads/:auctionId"
                    element={<AuctionAds />}
                  />
                  <Route
                    exact
                    path="/bid-list/:bidListId"
                    element={<BidList />}
                  />
                </Fragment>
              ) : (
                ""
              )}
              {/* Add permission Granted */}
              {auctionAccess?.permissions.find((o) => o.slug === "add") ? (
                <Route
                  exact
                  path="/create-auction"
                  element={<CreateAuction />}
                />
              ) : (
                ""
              )}
              {/* Update permission Granted */}
              {auctionAccess?.permissions.find((o) => o.slug === "update") ? (
                <Route
                  exact
                  path="/edit-auction/:auctionEventId"
                  element={<EditAuction />}
                />
              ) : (
                ""
              )}
            </Fragment>
          ) : (
            ""
          )}

          {/* Hotdeals */}
          {hotdealAccess ? (
            <Fragment>
              {/* Read permission Granted */}
              {hotdealAccess?.permissions.find((o) => o.slug === "read") ? (
                <Fragment>
                  <Route
                    exact
                    path="/hotdeal-events"
                    element={<HotdealEvents />}
                  />
                  <Route exact path="/hotdeals" element={<AllHotdeals />} />
                  <Route
                    exact
                    path="/hotdeals/:hotdealId"
                    element={<Hotdeal />}
                  />
                  <Route
                    exact
                    path="/hotdeal-ads/:hotdealId"
                    element={<HotdealAds />}
                  />
                </Fragment>
              ) : (
                ""
              )}
              {/* Add permission Granted */}
              {hotdealAccess?.permissions.find((o) => o.slug === "add") ? (
                <Route
                  exact
                  path="/create-hotdeal"
                  element={<CreateHotdeal />}
                />
              ) : (
                ""
              )}
              {/* Update permission Granted */}
              {hotdealAccess?.permissions.find((o) => o.slug === "update") ? (
                <Route
                  exact
                  path="/edit-hotdeal/:hotdealEventId"
                  element={<EditHotdeal />}
                />
              ) : (
                ""
              )}
            </Fragment>
          ) : (
            ""
          )}

          {/* CancelReservationRequest and vehicle reservation history permission Granted */}
          {hotdealvehicleAccess?.permissions.find((o) => o.slug === "read") ? (
            <>
              <Route
                exact
                path="/cancel-reservation-request"
                element={<CancelReservationRequest />}
              />
              <Route
                exact
                path="/vehicle-reservation-history"
                element={<DepositHistory />}
              />
            </>
          ) : (
            ""
          )}

          {/* Classic Ads */}
          {/* Read permission Granted */}
          {classicAdsAccess ? (
            classicAdsAccess?.permissions.find((o) => o.slug === "read") ? (
              <Route exact path="/classic-ads" element={<ClassicAdsList />} />
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {/* Bidlist */}
          {/* Read permission Granted */}
          {bidsAccess ? (
            bidsAccess?.permissions.find((o) => o.slug === "read") ? (
              <Route exact path="/bid-list" element={<BidLists />} />
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {/* Features */}
          {/* Read permission Granted */}
          {featuresAccess ? (
            featuresAccess?.permissions.find((o) => o.slug === "read") ? (
              <Route exact path="/features" element={<Features />} />
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {/* Users */}
          {/* Read permission Granted */}
          {usersAccess &&
          usersAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/users" element={<Users />} />
          ) : (
            ""
          )}
          {/* Add permission Granted */}
          {/* Invite Admin */}
          {usersAccess &&
          usersAccess?.permissions.find((o) => o.slug === "add") ? (
            <Route exact path="/invite-admin" element={<InviteUser />} />
          ) : (
            ""
          )}
          {/* Update permission Granted */}
          {usersAccess &&
          usersAccess?.permissions.find((o) => o.slug === "update") ? (
            <Route
              exact
              path="/user/create-censored/:userId"
              element={<CreateCensured />}
            />
          ) : (
            ""
          )}

          {(usersAccess &&
            usersAccess?.permissions.find((o) => o.slug === "read")) ||
          (verifyUser &&
            verifyUser?.permissions.find((o) => o.slug === "read")) ? (
            <Route
              exact
              path="/detail-member/:userId"
              element={<DetailMember />}
            />
          ) : (
            ""
          )}

          {(verifyUser &&
            verifyUser?.permissions.find((o) => o.slug === "read")) ||
          (validateUser &&
            validateUser?.permissions.find((o) => o.slug === "read")) ? (
            <Route exact path="/pending-verifications" element={<VerifyId />} />
          ) : (
            ""
          )}

          {/* {plansAccess&&plansAccess.permissions.find((o)=>o.slug==='read')? */}
          <Fragment>
            <Route exact path="/tags" element={<ForumsTable />} />
            <Route exact path="/create-tags" element={<CreateForums />} />
            <Route exact path="/edit-tags/:id" element={<CreateForums />} />
          </Fragment>
          {/* :''} */}

          {/* Role Management */}
          {/* Read permission Granted */}
          {roleAccess &&
          roleAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route
              exact
              path="/admin-roles-management"
              element={<AdminRoles />}
            />
          ) : (
            ""
          )}
          {/* Add permission Granted */}
          {roleAccess &&
          roleAccess?.permissions.find((o) => o.slug === "add") ? (
            <Route
              exact
              path="/create-admin-role"
              element={<CreateAdminRole />}
            />
          ) : (
            ""
          )}
          {/* Update permission Granted */}
          {roleAccess &&
          roleAccess?.permissions.find((o) => o.slug === "update") ? (
            <Route
              exact
              path="/edit-admin-role/:roleId"
              element={<EditAdminRole />}
            />
          ) : (
            ""
          )}

          {/* Plans */}
          {plansAccess &&
          plansAccess?.permissions.find((o) => o.slug === "read") ? (
            <Fragment>
              <Route exact path="/plans" element={<PlanTable />} />
              <Route
                exact
                path="/plan-description/:planId"
                element={<PlanDescription />}
              />
            </Fragment>
          ) : (
            ""
          )}
          {plansAccess &&
          plansAccess?.permissions.find((o) => o.slug === "add") ? (
            <Route exact path="/create-plan" element={<Plan />} />
          ) : (
            ""
          )}

          {/* Credits */}
          {plansAccess &&
          plansAccess?.permissions.find((o) => o.slug === "read") ? (
            <Fragment>
              <Route exact path="/credits" element={<Credits />} />
              <Route
                exact
                path="/credit-plan-details/:planId"
                element={<CreditPlanDetails />}
              />
              <Route exact path="/credit-history" element={<CreditHistory />} />
              <Route
                exact
                path="/credit-history-details/:creditId"
                element={<CreditHistoryDetails />}
              />
            </Fragment>
          ) : (
            ""
          )}

          {plansAccess &&
          plansAccess?.permissions.find((o) => o.slug === "add") ? (
            <Route exact path="/create-credits" element={<CreateCredits />} />
          ) : (
            ""
          )}

          {/* Tax Rates */}
          {taxRateAccess &&
          taxRateAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/tax-rates" element={<TaxRates />} />
          ) : (
            ""
          )}
          {taxRateAccess &&
          taxRateAccess?.permissions.find((o) => o.slug === "add") ? (
            <Route
              exact
              path="/create-tax-rates"
              element={<CreateTaxRates />}
            />
          ) : (
            ""
          )}
          {taxRateAccess &&
          taxRateAccess?.permissions.find((o) => o.slug === "update") ? (
            <Route
              exact
              path="/edit-tax-rates/:taxRateId"
              element={<EditTaxRates />}
            />
          ) : (
            ""
          )}

          {/* Coupon */}
          {couponAccess &&
          couponAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/coupons" element={<Coupon />} />
          ) : (
            ""
          )}
          {couponAccess &&
          couponAccess?.permissions.find((o) => o.slug === "add") ? (
            <Route exact path="/create-coupon" element={<CreateCoupon />} />
          ) : (
            ""
          )}
          {couponAccess &&
          couponAccess?.permissions.find((o) => o.slug === "update") ? (
            <Route
              exact
              path="/edit-coupon/:couponId"
              element={<EditCoupon />}
            />
          ) : (
            ""
          )}

          {/* Commission */}
          {commissionAccess &&
          commissionAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/commission" element={<Commission />} />
          ) : (
            ""
          )}
          {commissionAccess &&
          commissionAccess?.permissions.find((o) => o.slug === "add") ? (
            <Route
              exact
              path="/create-commission"
              element={<CreateCommission />}
            />
          ) : (
            ""
          )}

          {/* Company */}
          {companyAccess &&
          companyAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/companies" element={<Company />} />
          ) : (
            ""
          )}
          {companyAccess &&
          companyAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route
              exact
              path="/company-details/:companyId"
              element={<CompanyDetails />}
            />
          ) : (
            ""
          )}
          {companyAccess &&
          companyAccess?.permissions.find((o) => o.slug === "update") ? (
            <Route
              exact
              path="/company/create-censored/:companyId"
              element={<CreateCompanyCensured />}
            />
          ) : (
            ""
          )}

          {/* create a pro account */}
          {/* <Route exact path="/create-pro-account" element={<AddCompany />} /> */}

          {/* Dealers */}
          <Route exact path="/dealers" element={<ProspectDealers />} />
          <Route exact path="/import-csv" element={<ImportCsv />} />
          <Route exact path="/data-validate" element={<DataValidate />} />

          {/* Change Password */}
          <Route exact path="/change-password" element={<ChangePassword />} />

          {/* Slides */}
          {slideAccess &&
          slideAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/slides">
              <Fragment>
                <Route
                  index={true}
                  element={<Navigate replace to="/slides/partner-slider" />}
                />
                <Route exact path="partner-slider" element={<Slides2 />} />
                <Route exact path="home-slider" element={<Slider />} />
              </Fragment>
            </Route>
          ) : (
            ""
          )}

          {/* Invoices */}
          {invoiceAccess &&
          invoiceAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/invoices" element={<Invoices />} />
          ) : (
            ""
          )}

          {/* Import Files */}
          <Route exact path="/import-files" element={<ImportFile />} />

          {/* Terms and conditions */}
          {legalDocumentsAccess &&
          legalDocumentsAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/legal-documents">
              {/* List */}
              {legalDocumentsAccess?.permissions.find(
                (o) => o.slug === "read"
              ) ? (
                <Fragment>
                  <Route
                    index={true}
                    element={<Navigate replace to="/legal-documents/terms" />}
                  />
                  <Route exact path="preview/:recId" element={<Preview />} />
                  <Route exact path="terms" element={<Terms />} />
                  <Route exact path="nomad-terms" element={<NomadTerms />} />
                  <Route
                    exact
                    path="privacy-policy"
                    element={<PrivacyPolicy />}
                  />
                  <Route
                    exact
                    path="data-protection"
                    element={<DataProtection />}
                  />
                  <Route exact path="legal-notice" element={<LegalNotice />} />
                  <Route
                    exact
                    path="legal-documents"
                    element={<LegalDocuments />}
                  />
                </Fragment>
              ) : (
                ""
              )}

              {/* Add */}
              {legalDocumentsAccess?.permissions.find(
                (o) => o.slug === "add"
              ) ? (
                <Fragment>
                  <Route
                    index={true}
                    element={<Navigate replace to="/legal-documents/terms" />}
                  />
                  <Route exact path="add-terms" element={<AddTerms />} />
                  <Route
                    exact
                    path="add-nomad-terms"
                    element={<AddNomadTerms />}
                  />
                  <Route
                    exact
                    path="add-privacy-policy"
                    element={<AddPrivacyPolicy />}
                  />
                  <Route
                    exact
                    path="add-data-protection"
                    element={<AddDataProtection />}
                  />
                  <Route
                    exact
                    path="add-legal-notice"
                    element={<AddLegalNotice />}
                  />
                  <Route
                    exact
                    path="add-legal-documents"
                    element={<AddLegalDocuments />}
                  />
                </Fragment>
              ) : (
                ""
              )}

              {/* Edit */}
              {legalDocumentsAccess?.permissions.find(
                (o) => o.slug === "update"
              ) ? (
                <Fragment>
                  <Route
                    index={true}
                    element={<Navigate replace to="/legal-documents/terms" />}
                  />
                  <Route
                    exact
                    path="edit-terms/:recId"
                    element={<EditTerms />}
                  />
                  <Route
                    exact
                    path="edit-nomad-terms/:recId"
                    element={<EditNomadTerms />}
                  />
                  <Route
                    exact
                    path="edit-privacy-policy/:recId"
                    element={<EditPrivacyPolicy />}
                  />
                  <Route
                    exact
                    path="edit-data-protection/:recId"
                    element={<EditDataProtection />}
                  />
                  <Route
                    exact
                    path="edit-legal-notice/:recId"
                    element={<EditLegalNotice />}
                  />
                  <Route
                    exact
                    path="edit-legal-documents/:recId"
                    element={<EditLegalDocuments />}
                  />
                </Fragment>
              ) : (
                ""
              )}
            </Route>
          ) : (
            ""
          )}

          {/* Manage Vehicles */}
          {vehicleManagementAccess &&
          vehicleManagementAccess?.permissions.find(
            (o) => o.slug === "read"
          ) ? (
            <Route exact path="/vehicles-management">
              {/* List */}
              {vehicleManagementAccess?.permissions.find(
                (o) => o.slug === "read"
              ) ? (
                <Fragment>
                  <Route
                    index={true}
                    element={
                      <Navigate
                        replace
                        to="/vehicles-management/vehicle-brands"
                      />
                    }
                  />
                  <Route exact path="vehicle-brands" element={<Brand />} />
                  <Route exact path="vehicle-models" element={<Modals />} />
                  <Route
                    exact
                    path="vehicle-model-items"
                    element={<ModalItems />}
                  />
                  <Route
                    exact
                    path="vehicle-tire-size"
                    element={<TyreSize />}
                  />
                  <Route
                    exact
                    path="vehicle-tire-types"
                    element={<TyreType />}
                  />
                  <Route
                    exact
                    path="vehicle-versions"
                    element={<VersionCars />}
                  />
                  <Route
                    exact
                    path="euro-standards"
                    element={<EuroStandards />}
                  />
                </Fragment>
              ) : (
                ""
              )}

              {/* Create  */}
              {vehicleManagementAccess?.permissions.find(
                (o) => o.slug === "add"
              ) ? (
                <Fragment>
                  <Route
                    exact
                    path="create-vehicle-brands"
                    element={<CreateBrand />}
                  />
                  <Route
                    exact
                    path="create-vehicle-model"
                    element={<CreateModals />}
                  />
                  <Route
                    exact
                    path="create-vehicle-model-items"
                    element={<CreateModalsItems />}
                  />
                  <Route
                    exact
                    path="create-tire-size"
                    element={<CreateVehicleTireSizes />}
                  />
                  <Route
                    exact
                    path="create-tire-types"
                    element={<CreateVehicleTireTypes />}
                  />
                  <Route
                    exact
                    path="create-versions"
                    element={<CreateVersionCar />}
                  />
                </Fragment>
              ) : (
                ""
              )}

              {/* Update */}
              {vehicleManagementAccess?.permissions.find(
                (o) => o.slug === "update"
              ) ? (
                <Fragment>
                  <Route
                    exact
                    path="edit-vehicle-brands/:brandsId"
                    element={<EditBrand />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-model/:modalId"
                    element={<EditModals />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-model-items/:modalId"
                    element={<EditModalsItems />}
                  />
                  <Route
                    exact
                    path="edit-tire-size/:tireSizeId"
                    element={<EditVehicleTyreSizes />}
                  />
                  <Route
                    exact
                    path="edit-tire-types/:tireTypeId"
                    element={<EditVehicleTireTypes />}
                  />
                  <Route
                    exact
                    path="edit-versions/:versionCarId"
                    element={<EditVersionCar />}
                  />
                </Fragment>
              ) : (
                ""
              )}
            </Route>
          ) : (
            ""
          )}

          {/* Translate */}
          {tanslationAccess &&
          tanslationAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/translation">
              {/* List */}
              {tanslationAccess?.permissions.find((o) => o.slug === "read") ? (
                <Fragment>
                  <Route index={true} element={<Language />} />
                  <Route exact path="department" element={<Department />} />
                  <Route exact path="feature" element={<Feature />} />
                  <Route
                    exact
                    path="feature/permissions/:featureId"
                    element={<Permission />}
                  />
                  <Route
                    exact
                    path="vehicle-product-category"
                    element={<ProductCategory />}
                  />
                  <Route
                    exact
                    path="vehicle-battery-position"
                    element={<BatteryPosition />}
                  />
                  {/* <Route exact path="vehicle-battery-types" element={<BatteryTypes />} /> */}
                  <Route exact path="vehicle-body" element={<Body />} />
                  <Route exact path="vehicle-brakes" element={<Brakes />} />
                  <Route exact path="vehicle-color" element={<Color />} />
                  <Route
                    exact
                    path="vehicle-conditions"
                    element={<VehicleConditions />}
                  />
                  <Route
                    exact
                    path="vehicle-driving-wheel"
                    element={<DrivingWheel />}
                  />
                  <Route
                    exact
                    path="vehicle-drive-types"
                    element={<DriveTypes />}
                  />
                  <Route exact path="vehicle-engine" element={<Engines />} />
                  <Route
                    exact
                    path="vehicle-engine-position"
                    element={<EnginePosition />}
                  />
                  <Route
                    exact
                    path="vehicle-equipments"
                    element={<Equipment />}
                  />
                  <Route
                    exact
                    path="vehicle-equipments-category"
                    element={<EquipmentCategory />}
                  />
                  <Route
                    exact
                    path="vehicle-expertise"
                    element={<Expertise />}
                  />
                  <Route exact path="status" element={<Status />} />
                  <Route
                    exact
                    path="vehicle-frame-sizes"
                    element={<FrameSizes />}
                  />
                  <Route exact path="vehicle-fuel" element={<Fuel />} />
                  <Route exact path="vehicle-gearbox" element={<GearBox />} />
                  <Route
                    exact
                    path="vehicle-interiors"
                    element={<Interiors />}
                  />
                  <Route
                    exact
                    path="vehicle-materials"
                    element={<Materials />}
                  />
                  <Route
                    exact
                    path="vehicle-product-types"
                    element={<ProductType />}
                  />
                  <Route exact path="vehicle-sex" element={<Sex />} />
                  <Route
                    exact
                    path="vehicle-suspensions"
                    element={<Suspensions />}
                  />
                  <Route
                    exact
                    path="vehicle-target-audiences"
                    element={<TargetAudiences />}
                  />
                  <Route exact path="services" element={<Service />} />
                  <Route
                    exact
                    path="front-office-text"
                    element={<SuggestionText />}
                  />
                  <Route
                    exact
                    path="back-office-text"
                    element={<BackOfficeText />}
                  />
                  <Route
                    exact
                    path="user-behavior-text"
                    element={<BehaviorText />}
                  />
                  <Route
                    exact
                    path="subsidiary-behavior-text"
                    element={<SubsidiaryBehaviorText />}
                  />
                  <Route
                    exact
                    path="forum-behavior-text"
                    element={<ForumBehaviourText />}
                  />
                  <Route
                    exact
                    path="ad-removal-reasons"
                    element={<AdRemovalReasons />}
                  />
                  <Route exact path="countries" element={<Countries />} />
                  <Route exact path="continents" element={<Continents />} />
                  <Route
                    exact
                    path="partner-options"
                    element={<PartnerOptions />}
                  />
                  <Route
                    exact
                    path="contact-options"
                    element={<ContactOptions />}
                  />
                  <Route
                    exact
                    path="credit-history-reasons"
                    element={<CreditHistoryReasons />}
                  />
                </Fragment>
              ) : (
                ""
              )}

              {/* Create  */}
              {tanslationAccess?.permissions.find((o) => o.slug === "add") ? (
                <Fragment>
                  <Route
                    exact
                    path="create-language"
                    element={<CreateLanguage />}
                  />
                  <Route
                    exact
                    path="create-department"
                    element={<CreateDepartment />}
                  />
                  <Route
                    exact
                    path="create-battery-position"
                    element={<CreateVehicleBatteryPosition />}
                  />
                  {/* <Route exact path="create-battery-types" element={<CreateVehicleBatteryTypes />} /> */}
                  <Route
                    exact
                    path="create-vehicle-body"
                    element={<CreateVehicleBody />}
                  />
                  <Route
                    exact
                    path="create-vehicle-brakes"
                    element={<CreateVehicleBrakes />}
                  />
                  <Route
                    exact
                    path="create-vehicle-color"
                    element={<CreateVehicleColor />}
                  />
                  <Route
                    exact
                    path="create-vehicle-conditions"
                    element={<CreateVehicleConditions />}
                  />
                  <Route
                    exact
                    path="create-driving-wheel"
                    element={<CreateVehicleDrivingWheel />}
                  />
                  <Route
                    exact
                    path="create-drive-types"
                    element={<CreateVehicleDriveTypes />}
                  />
                  <Route
                    exact
                    path="create-vehicle-engine"
                    element={<CreateVehicleEngine />}
                  />
                  <Route
                    exact
                    path="create-engine-position"
                    element={<CreateVehicleEnginePositions />}
                  />
                  <Route
                    exact
                    path="create-vehicle-equipments"
                    element={<CreateVehicleEquipment />}
                  />
                  <Route
                    exact
                    path="create-vehicle-equipments-category"
                    element={<CreateVehicleEquipmentCategory />}
                  />
                  <Route
                    exact
                    path="create-vehicle-frame-sizes"
                    element={<CreateVehicleFrameSizes />}
                  />
                  <Route
                    exact
                    path="create-vehicle-fuel"
                    element={<CreateVehicleFuel />}
                  />
                  <Route
                    exact
                    path="create-vehicle-gearbox"
                    element={<CreateVehicleGear />}
                  />
                  <Route
                    exact
                    path="create-vehicle-interiors"
                    element={<CreateVehicleInteriors />}
                  />
                  <Route
                    exact
                    path="create-vehicle-materials"
                    element={<CreateVehicleMaterials />}
                  />
                  <Route
                    exact
                    path="create-product-types"
                    element={<CreateProductType />}
                  />
                  <Route exact path="create-sex" element={<CreateSex />} />
                  <Route
                    exact
                    path="create-vehicle-suspensions"
                    element={<CreateVehicleSuspensions />}
                  />
                  <Route
                    exact
                    path="create-target-audiences"
                    element={<CreateTargetAudiences />}
                  />
                  <Route
                    exact
                    path="create-service"
                    element={<CreateService />}
                  />
                  <Route
                    exact
                    path="create-front-office-text"
                    element={<CreateSuggestionText />}
                  />
                  <Route
                    exact
                    path="create-back-office-text"
                    element={<CreateBackOfficeText />}
                  />
                  <Route
                    exact
                    path="create-user-behavior-text"
                    element={<CreateBehaviourText />}
                  />
                  <Route
                    exact
                    path="create-forum-behavior-text"
                    element={<CreateForumBehaviourText />}
                  />
                  <Route
                    exact
                    path="create-subsidiary-behavior-text"
                    element={<CreateSubsidiaryBehaviourText />}
                  />
                  <Route
                    exact
                    path="create-ad-removal-reasons"
                    element={<CreateAdRemovalReasons />}
                  />
                  <Route
                    exact
                    path="create-credit-history-reasons"
                    element={<CreateCreditHistoryReasons />}
                  />
                  <Route
                    exact
                    path="create-partner-options"
                    element={<CreatePartners />}
                  />
                  <Route
                    exact
                    path="create-contact-options"
                    element={<CreateContactOptions />}
                  />
                </Fragment>
              ) : (
                ""
              )}

              {/* Update */}
              {tanslationAccess?.permissions.find(
                (o) => o.slug === "update"
              ) ? (
                <Fragment>
                  <Route
                    exact
                    path="edit-language/:language"
                    element={<EditLanguage />}
                  />
                  <Route
                    exact
                    path="edit-department/:department"
                    element={<EditDepartment />}
                  />
                  <Route
                    exact
                    path="edit-feature/:featureId"
                    element={<EditFeature />}
                  />
                  <Route
                    exact
                    path="feature/edit-permission/:permissionId"
                    element={<EditPermission />}
                  />
                  <Route
                    exact
                    path="edit-battery-position/:batteryPositionId"
                    element={<EditVehicleBatteryPosition />}
                  />
                  {/* <Route exact path="edit-battery-types/:batteryTypeId" element={<EditVehicleBatteryTypes />} /> */}
                  <Route
                    exact
                    path="edit-vehicle-body/:bodyId"
                    element={<EditVehicleBody />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-brakes/:brakesId"
                    element={<EditVehicleBrakes />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-color/:colorId"
                    element={<EditVehicleColor />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-conditions/:conditionId"
                    element={<EditVehicleConditions />}
                  />
                  <Route
                    exact
                    path="edit-driving-wheel/:drivingWheelsId"
                    element={<EditVehicleDrivingWheel />}
                  />
                  <Route
                    exact
                    path="edit-drive-types/:driveTypesID"
                    element={<EditVehicleDriveTypes />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-engine/:engineId"
                    element={<EditVehicleEngine />}
                  />
                  <Route
                    exact
                    path="edit-engine-position/:enginePosition"
                    element={<EditVehicleEnginePosition />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-equipments/:equipmentId"
                    element={<EditVehicleEquipment />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-equipments-category/:equipmentCategoryId"
                    element={<EditVehicleEquipmentCategory />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-expertise/:expertiseId"
                    element={<EditVehicleExpertise />}
                  />
                  <Route
                    exact
                    path="edit-status/:statusId"
                    element={<EditStatus />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-frame-sizes/:frameSizeId"
                    element={<EditVehicleFrameSizes />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-fuel/:fuelId"
                    element={<EditVehicleFuel />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-gearbox/:gearboxId"
                    element={<EditVehicleGear />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-interiors/:interiorsId"
                    element={<EditVehicleInteriors />}
                  />
                  <Route
                    exact
                    path="edit-vehicle-materials/:materialsId"
                    element={<EditVehicleMaterials />}
                  />
                  <Route
                    exact
                    path="edit-product-types/:productTypeId"
                    element={<EditProductType />}
                  />
                  <Route exact path="edit-sex/:sexId" element={<EditSex />} />
                  <Route
                    exact
                    path="edit-vehicle-suspensions/:suspensionsId"
                    element={<EditVehicleSuspensions />}
                  />
                  <Route
                    exact
                    path="edit-target-audiences/:targetAudiencesId"
                    element={<EditTargetAudiences />}
                  />
                  <Route
                    exact
                    path="edit-service/:serviceId"
                    element={<EditService />}
                  />
                  <Route
                    exact
                    path="edit-front-office-text/:textId"
                    element={<EditSuggestionText />}
                  />
                  <Route
                    exact
                    path="edit-back-office-text/:textId"
                    element={<EditBackOfficeText />}
                  />
                  <Route
                    exact
                    path="edit-user-behavior-text/:textId"
                    element={<EditBehaviorText />}
                  />
                  <Route
                    exact
                    path="edit-forum-behavior-text/:textId"
                    element={<EditForumBehaviorText />}
                  />
                  <Route
                    exact
                    path="edit-subsidiary-behavior-text/:textId"
                    element={<EditSubsidiaryBehaviorText />}
                  />
                  <Route
                    exact
                    path="edit-ad-removal-reasons/:textId"
                    element={<EditAdRemovalReasons />}
                  />
                  <Route
                    exact
                    path="edit-credit-history-reasons/:textId"
                    element={<EditCreditHistoryReasons />}
                  />
                  <Route
                    exact
                    path="edit-country/:countryId"
                    element={<EditCountry />}
                  />
                  <Route
                    exact
                    path="edit-continent/:continentId"
                    element={<EditContinent />}
                  />
                  <Route
                    exact
                    path="edit-partner-options/:partnerID"
                    element={<EditPartners />}
                  />
                  <Route
                    exact
                    path="edit-contact-options/:contactID"
                    element={<EditContactOptions />}
                  />
                </Fragment>
              ) : (
                ""
              )}
            </Route>
          ) : (
            ""
          )}

          {/* FAQ */}
          {faqAccess &&
          faqAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/faqs" element={<Faqs />} />
          ) : (
            ""
          )}
          {/* Add permission Granted */}
          {faqAccess && faqAccess?.permissions.find((o) => o.slug === "add") ? (
            <Route exact path="/create-faqs" element={<CreateFaqs />} />
          ) : (
            ""
          )}
          {/* Update permission Granted */}
          {faqAccess &&
          faqAccess?.permissions.find((o) => o.slug === "update") ? (
            <Route exact path="/edit-faqs/:faqId" element={<EditFaqs />} />
          ) : (
            ""
          )}

          {/* Error Log */}
          {logsAccess &&
          logsAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/server-logs" element={<Logger />} />
          ) : (
            ""
          )}

          {/* Partners */}
          {partnersAccess &&
          partnersAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/partners" element={<Partners />} />
          ) : (
            ""
          )}
          {/* Contact us */}
          {contactAccess &&
          contactAccess?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/contacts" element={<ContactUs />} />
          ) : (
            ""
          )}

          <Route exact path="/flagged-review" element={<FlaggedReview />} />
          <Route exact path="/forum-flags" element={<ForumFlagComments />} />
          <Route exact path="/data-provider" element={<DataProvider />} />
          <Route
            exact
            path="/data-providers-errors"
            element={<DataProvidersErrors />}
          />
          {importedDealer &&
          importedDealer?.permissions.find((o) => o.slug === "delete") &&
          importedDealer?.permissions.find((o) => o.slug === "add") ? (
            <Route exact path="/advertisers" element={<Advertisers />} />
          ) : (
            ""
          )}
          <Route exact path="/volkswagen" element={<Volkswagen />} />

          {/* chat messenger */}
          {chats && chats?.permissions.find((o) => o.slug === "read") ? (
            <Route exact path="/messenger" element={<Messanger />} />
          ) : (
            ""
          )}

          <Route
            exact
            path="/download-invoice-zip/:userId/:zipToken"
            element={<DownloadZipFile />}
          />
          <Route exact path="/general-settings" element={<GeneralSettings />} />

          <Route
            exact
            path="/custom-notifications"
            element={<Notifications />}
          />
          <Route
            exact
            path="/trigger-new-notification"
            element={<AddNewNotification />}
          />

          {/* Default */}
          <Route
            exact
            path="*"
            element={<Navigate replace to="/dashboard" />}
          />
        </Routes>
      </MainLayout>
    </>
  );
};

export default PrivateRoute;
